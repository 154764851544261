module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162359197-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#4BACEC","theme_color":"#4BACEC","display":"minimal-ui","icon":"src/images/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"487b50ed3230b7eef38fbd97d8258d43"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
